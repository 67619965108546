* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
body {
  background-color: #f0f0f0;
  color: #191919;
  margin: 0;
  margin-bottom: 100px;
  min-height: 400px;
  clear: both;
}

h1,
h2,
h3,
h4 {
  color: #5dcad1;
  font-family: 'Nunito Sans', sans-serif;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3.8rem;
}

p {
  font-family: 'Nunito Sans', sans-serif;

  font-size: 2rem;
}

a {
  font-family: 'Nunito Sans', sans-serif;
  color: #1c2143;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  color: #282e53;
}

a:focus {
  color: #171b35;
}
