.contact-form form {
  opacity: 0.95;
  color: #1c2143;
  text-align: left;
  max-width: 75%;
  padding: 10px;
  background-color: #f3f3f3;
  border: transparent;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
    -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.15);
}

.contact-form label {
  min-width: 130px;
  text-align: left;
  padding-top: 10px;
  padding-left: 20px;
  margin-top: 10px;
}

.contact-form input {
  display: flex;
  align-items: flex-end;
  font-family: 'Nunito Sans', sans-serif;
  outline: none;
  width: 70%;
  border: none;
  padding: 10px;
  margin-left: 20px;
  border-radius: 2px;
  background: #fafafa;
  font-size: 2rem;
  border: transparent;
  box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
    inset -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.1),
    inset 2px 2px 8px rgba(0, 0, 0, 0.15);
}

.contact-form textarea {
  display: flex;
  align-items: flex-end;
  overflow: auto;
  font-family: 'Nunito Sans', sans-serif;
  outline: none;
  resize: none;
  padding: 20px;
  margin-left: 20px;
  border-radius: 2px;
  background: #fafafa;
  font-size: 2rem;
  border: transparent;
  box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
    inset -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.1),
    inset 2px 2px 8px rgba(0, 0, 0, 0.15);
  width: 90%;
}

button {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: normal;
  font-size: 2rem;
  background: #e7d4fa;
  border-radius: 20px;
  border: transparent;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
    -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  margin: 0;
  margin-top: 24px;
  color: #1c2143;
  cursor: pointer;
  text-align: center;
  outline: none;
  transition: background 0.5s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover {
  background: #f4eaff;
  box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
    inset -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.1),
    inset 2px 2px 8px rgba(0, 0, 0, 0.15);
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

@media screen and (max-width: 375px) {
  .contact-form form {
    max-width: 225px;
    padding: 10px;
  }

  .contact-form label {
    padding-left: 5px;
  }

  .contact-form input {
    margin-left: 5px;
    min-width: 170px;
  }

  .contact-form textarea {
    margin-left: 5px;
    width: 75%;
  }
}
