.about {
  display: block;
  padding: 20px;
  width: 90%;
  margin: auto;
}

.about-content-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.about-content-text {
  flex-wrap: wrap;
}

#about-img {
  max-height: 350px;
  margin-top: 0;
  border-radius: 50%;
  padding: 1rem;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
}

.about h1 {
  color: #1c2143;
  font-weight: normal;
  text-align: left;
  font-size: 6rem;
  margin-top: 0;
}

.about p {
  line-height: 1.5em;
  padding: 1rem;
}

.about ul {
  list-style: none;
  padding: 0;
  max-width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.about h3 {
  font-size: 4rem;
  color: #1c2143;
  margin: 10px 0px;
}

.about li {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: normal;
  font-size: 2rem;
  background: #f3edf9;
  border-radius: 20px;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
    -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.15);
  width: 200px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  margin: 10px;
  color: #1c2143;
  cursor: default;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.about-skills {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about a:hover {
  background-color: #e7d4fa;
}

@media screen and (max-width: 825px) {
  .about-content-main {
    flex-wrap: wrap;
  }

  #about-img {
    height: 250px;
    display: block;
    margin: 0 auto;
    padding: 0;
  }
}

@media screen and (max-width: 375px) {
  #about-img {
    height: 200px;
    display: block;
    margin: 0 auto;
  }
}
