.project-card {
  width: 100%;
  padding: 18px;
  margin: 10px 0px;
  color: #1c2143;
  border-radius: 20px;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
    -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.15);
}

.project-card h3 {
  padding-bottom: 10px;
  color: #1c2143;
  font-family: 'Jost', sans-serif;
  font-size: 2rem;
}

button {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: normal;
  font-size: 2rem;
  background: #e7d4fa;
  border-radius: 20px;
  border: transparent;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
    -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  margin: 0;
  margin-top: 24px;
  color: #1c2143;
  cursor: pointer;
  text-align: center;
  outline: none;
  transition: background 0.5s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover {
  background: #f4eaff;
  box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
    inset -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.1),
    inset 2px 2px 8px rgba(0, 0, 0, 0.15);
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}
