.masthead {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  height: auto;
  overflow: hidden;
  padding: 10rem 5rem;
}

.masthead h1 {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #171b35;
  font-size: 8vmin;
  letter-spacing: 0.03em;
  line-height: 1;
  margin-bottom: 30px;
  text-align: left;
  padding-top: 10px;
  cursor: default;
}

.masthead h2 {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 2.5rem;
  justify-content: left;
  text-align: left;
  color: #1c2143;
  line-height: 4rem;
}

.masthead button {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: normal;
  font-size: 2rem;
  background: #e7d4fa;
  border-radius: 20px;
  border: transparent;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
    -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.2), 2px 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  margin: 0;
  margin-top: 24px;
  color: #1c2143;
  cursor: pointer;
  text-align: center;
  transition: background 0.5s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.masthead button:hover {
  background: #f4eaff;
  box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
    inset -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.1),
    inset 2px 2px 8px rgba(0, 0, 0, 0.15);
}

.masthead button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.text-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

#hero-graphic {
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
}

@media screen and (max-width: 1100px) {
  #hero-graphic {
    display: block;
    left: 0;
    bottom: 0;
    margin: 0 auto;
  }
}

@media screen and (max-width: 400px) {
  .masthead {
    margin: 0;
    width: 100%;
    padding-top: 10px;
  }

  .masthead button {
    width: 200px;
  }

  #hero-graphic {
    height: 30rem;
  }
}
