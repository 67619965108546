.blog {
  display: block;
  padding: 20px;
  width: 90%;
  margin: auto;
  background-image: url('../../assets/rainbowreading.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 400px;
}

.blog h1 {
  color: #171b35;
  font-weight: normal;
  text-align: left;
  font-size: 6rem;
  margin-top: 0;
}

.blog h4 {
  font-family: 'Jost', sans-serif;
  color: #191919;
}

#construction-emoji {
  font-size: 2em;
}
