.contact {
  display: block;
  padding: 20px;
  width: 90%;
  margin: auto;
  background-image: url('../../assets/wifiwow.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 400px;
}

.contact h1 {
  color: #171b35;
  font-weight: normal;
  text-align: left;
  font-size: 6rem;
  margin-top: 0;
}

.contact p {
  padding: 10px 0px;
  font-size: 2.75rem;
}

@media screen and (max-width: 375px) {
  .contact {
    font-size: 0.75em;
    margin: 0 auto;
  }
}
