.posts-display {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 100rem;
  opacity: 0.95;
}

.post-item {
  background-color: #f3f3f3;
}

.blog-cover-img {
  max-width: 100%;
}

.posts-display h2,
p {
  color: #1c2143;
  padding: 0 5px 5px 5px;
}

.posts-display a {
  font-weight: bold;
  text-decoration: none;
}

ul {
  margin: 0;
  padding-left: 0;
}

li {
  list-style: none;
}
