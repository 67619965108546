.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 8vh;
  width: 90%;
  margin: auto;
  padding: 1rem 0 1rem 0;
}

.nav li {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  line-height: 0.25;
  margin: 2rem 0 0 0;
  padding: 0 1rem 0 1rem;
  font-size: 2.5rem;
}


@media screen and (max-width: 942px) {
  .nav {
    text-align: center;
    margin-bottom: 5px;
    background-color: transparent;
    font-size: 1rem;
  }

  .nav li {
    font-size: 2rem;
  }
}

.styled-link {
  text-decoration: none;
  color: #1c2143;
  justify-content: right;
}

.styled-link:hover {
  background-color: #e7d4fa;
  transition: background-color 0.75s;
}

.styled-link:last-of-type {
  margin-right: 0;
}

.icon-link {
  font-family: 'Nunito Sans', sans-serif;
  text-decoration: none;
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  height: 75px;
  width: 200px;
  border: 0.25rem solid #1c2143;
}

.icon-link:hover {
  background-color: #e7d4fa;
  transition: 0.75s;
}

@media screen and (max-width: 440px) {
  .styled-link {
    margin: 1rem 0 0 0;
  }

  .logo {
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 4rem;
  }
}
