.project-display {
  display: block;
  padding: 20px;
  width: 90%;
  margin: auto;
}

.project-display h1 {
  color: #1c2143;
  font-weight: normal;
  text-align: left;
  font-size: 6rem;
  margin-top: 0;
}

.cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 40px;
}
